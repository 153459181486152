import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import reportWebVitals from './reportWebVitals';

import App from './components/App';

import { store, persistor } from './store';

import NavigationRoutes from './routes';

import { initGA4, initGTM, initHotjar, MetaPixel } from './helpers/tracking';

export const Root = () => {
  // modify query client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // deployment temporary fix - till prod

  // disable react dev tools if not in development
  if (process.env.NODE_ENV !== 'development') {
    disableReactDevTools();
  };

  useEffect(() => {
    initGA4();
    initGTM();
    initHotjar();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename='/'>
            <MetaPixel />
            <App>
              <NavigationRoutes />
            </App>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root tab="root" />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
