import React, { useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import CountUp from 'react-countup';

import { BsX, BsExclamationCircle, BsHeartFill } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { iconAssets, imageAssets } from "../../constants/assets_url";

import { getSessionData, setSessionData } from '../../store/slices/global';
import { isModalActive, closeModal } from "../../store/slices/modal";

import useDisableDisplayTour from "../../hooks/auth/useDisableDisplayTour";
import { getTrackingPath } from "../../helpers/tracking";

const modalName = "ON_BORDING_MODAL";
const modalTitle = "Onbording";
const modalClass = "onbording";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const OnbordingModal = () => {

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const sessionData = useSelector(getSessionData());
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isProcessRunning, setIsProcessRunning] = useState(false); // eslint-disable-line no-unused-vars

    const { mutate: disableDisplayTour } = useDisableDisplayTour();

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - 1
        );
    };

    const resetModalStates = () => {
        setCurrentIndex(0);
        setIsProcessRunning(false);
    };


    const onCloseModal = () => {
        resetModalStates();
        dispatch(closeModal(modalName));
    };


    const onMakeDone = (e) => {
        e.preventDefault();
        setIsProcessRunning(true);

        disableDisplayTour({
            payload: {},
            successCallback: async () => {
                dispatch(setSessionData({ ...sessionData, display_tour: false }));
                onCloseModal()
                setIsProcessRunning(false);
            },
            errorCallback: (err) => {
                setIsProcessRunning(false);
            }
        });
    }

    const onGoToDisclaimer = (e) => {
        e.preventDefault();

        window.location.href = getTrackingPath('/questionnaire/disclaimer');
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className="imm__onbording_inner_wrap">
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <div className="imm__onbording__slider">
                        {currentIndex !== 0 && (<button className="imm__onbording__slider_arrow arrow_left" onClick={prevSlide}>
                            <IoIosArrowBack size={35} />
                        </button>)}
                        <div className="imm__onbording__slider_items" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>

                            <div className={`imm__onbording__slider_item ${currentIndex === 0 ? 'active' : ''}`}>
                                <p className="imm__onbording__slider_item__title">You may need this Score to apply for your PR 🇨🇦</p>
                                <div className="imm__onbording__score_progress">
                                    <div className="imm__onboarding__score_progress__progressbar">
                                        <CircularProgressbarWithChildren
                                            value={95}
                                            strokeWidth={5}
                                            styles={buildStyles({
                                                pathTransitionDuration: 1,
                                                trailColor: '#AEC3ED',
                                                pathColor: "#39B54A",
                                                strokeLinecap: 'round',
                                            })}
                                        >
                                            <p>Your Score</p>
                                            <CountUp end={620} duration={1} />
                                            <br />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <p className="imm__onbording__score_progress__p1">YOU QUALIFY!</p>
                                    <p>CANADIAN EXPERIENCE CLASS</p>
                                    <p className="wt-600">MIN SCORE</p>
                                    <p className="imm__onbording__score_progress__p2">522</p>

                                    <button className='btn_block imm__regular__btn imm__btn__primary' onClick={onGoToDisclaimer}>
                                        Get your Score!
                                    </button>
                                </div>
                            </div>

                            <div className={`imm__onbording__slider_item ${currentIndex === 1 ? 'active' : ''}`}>
                                <p className="imm__onbording__slider_item__title">Your life can change in <br />7-minutes, for free! 🤯</p>
                                <div className="imm__onbording__score_matrix">
                                    <img src={imageAssets.ScoreMatrix} alt="" />

                                    <button className='btn_block imm__regular__btn imm__btn__primary' onClick={onGoToDisclaimer}>
                                        Get your Score!
                                    </button>
                                </div>
                            </div>

                            <div className={`imm__onbording__slider_item ${currentIndex === 2 ? 'active' : ''}`}>
                                <p className="imm__onbording__slider_item__title">No More Scams! ❌️</p>
                                <div className="imm__onbording__hypo_preview">
                                    <p className="color_dark wt-500 mt-3 mb-3">Immproved provides <strong>honest</strong> and <strong>transparent</strong> advice for free.</p>
                                    <p className="color_dark wt-500 mb-4">Before you pay someone money, make sure you are well-informed.</p>

                                    <div className='imm__onbording__score_hypo_card easy'>
                                        <div className='imm__shc__top'>
                                            <div className='imm__shc__score_success'>
                                                496pts
                                            </div>
                                            <div className={`imm__shc__difficulty imm__shc__d__easier`}>
                                                <div className='imm__shc__dots'>
                                                    <span /><span /><span />
                                                </div>
                                                <span className="imm__shc__difficulty_title">Easy</span>
                                            </div>
                                        </div>
                                        <div className='imm__shc__hypo_content'>
                                            <div className='imm__shc__hypo_content_bullet'>
                                                <div className='imm__shc__hypo_content__dot' />
                                                <span>Get additional 12 months of Canadian work experience</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='imm__onbording__score_hypo_card hard'>
                                        <div className="no_bs"><img className="fit_asset_max" src={imageAssets.NoBs} alt="" /></div>

                                        <div className='imm__shc__top'>
                                            <div className='imm__shc__score_hard'>
                                                515pts
                                            </div>
                                            <div className={`imm__shc__difficulty imm__shc__d__hard`}>
                                                <div className='imm__shc__dots'>
                                                    <span /><span /><span />
                                                </div>
                                                <span className="imm__shc__difficulty_title">Hard</span>
                                            </div>
                                        </div>
                                        <div className='imm__shc__hypo_content'>
                                            <div className='imm__shc__hypo_content_bullet'>
                                                <div className='imm__shc__hypo_content__dot' />
                                                <span>Improve language score to CLB 9</span>
                                            </div>
                                        </div>
                                    </div>

                                    <br /><br />
                                    <button className='btn_block imm__regular__btn imm__btn__primary' onClick={onGoToDisclaimer}>
                                        Get your Score!
                                    </button>
                                </div>
                            </div>

                            <div className={`imm__onbording__slider_item ${currentIndex === 3 ? 'active' : ''}`}>
                                <p className="imm__onbording__slider_item__title">No need to go <br />anywhere else! 😎</p>
                                <div className="imm__onbording__notification_preview">
                                    <p className="color_dark wt-500 mt-3 mb-3">Once you use <strong>Immproved</strong>, you won't need to look elsewhere.</p>
                                    <p className="color_dark wt-500 mb-5">Manage your status, navigate <strong>immigration changes</strong>, and much more!</p>

                                    <div className={`imm__home_page_navigation_card imm__hpnc__status__unavailable`}>
                                        <BsExclamationCircle className="imm__hpnc__status_icon color_error" />
                                        <div className="imm__hpnc__left">
                                            <img className="fit_asset_max" src={iconAssets.NotificationsIcon} alt="" />
                                        </div>
                                        <div className="imm__hpnc__right">
                                            <h5>Notifications</h5>
                                            <p className="mice pt-1">Work permit is expiring in 2 weeks!</p>
                                        </div>
                                    </div>

                                    <br /><br />
                                    <button className='btn_block imm__regular__btn imm__btn__primary' onClick={onGoToDisclaimer}>
                                        Get your Score!
                                    </button>
                                </div>
                            </div>

                            <div className={`imm__onbording__slider_item ${currentIndex === 4 ? 'active' : ''}`}>
                                <p className="imm__onbording__slider_item__title">It's pretty SMART! 🧠</p>
                                <div className="imm__onbording__program_preview">
                                    <h4 className="color_dark wt-400 mt-3">Built by <strong>Canada's leading immigration experts</strong>, Immproved provides recommendations that would otherwise take hours of research or cost you $$$ to discover.</h4>

                                    <div className="imm__onbording__program_card mt-3">
                                        <div className="imm__pc__header">
                                            <p className="wt-600">British Columbia PNP: Entrepreneur Immigration Base stream</p>
                                            <button className="imm__pc__heart"><BsHeartFill className="heart_fill" /></button>
                                        </div>
                                        <div className="imm__pc__content">
                                            <p className="imm__pc__description">This stream is for experienced entrepreneurs who intend to establish new businesses or take over and grow existing businesses in B.C.</p>
                                        </div>
                                    </div>

                                    <br /><br />
                                    <button className='btn_block imm__regular__btn imm__btn__primary' onClick={onGoToDisclaimer}>
                                        Get your Score!
                                    </button>
                                </div>
                            </div>

                        </div>
                        <button className="imm__onbording__slider_arrow arrow_right" onClick={currentIndex === 4 ? onMakeDone : nextSlide}>
                            <IoIosArrowForward size={35} />
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default OnbordingModal;